@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root{
  height: 100%;
}

.dashboard-body-layout {
  height: calc(100% - 64px);
}

.side-bar a.active {
  @apply bg-primary;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}
  
.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
  font-size: inherit;
  text-decoration: none !important;
  color: white;
}

.dropdown {
  position: absolute;
  left: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 9999;
  min-width: 12rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}
.dropdown a {
  color: black;
}
.dropdown a:hover {
  color: white;
}
.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  top: -7px;
}

.pull-left {
  right: 100%;
}
.pull-right {
  left: 100%;
}
